import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-mylayout',
  templateUrl: './mylayout.component.html',
  styleUrls: ['./mylayout.component.scss']
})
export class MylayoutComponent {
 
}
